<template>
    <div class="verify_account">
        <div v-if="paymentIntentLoader" class="area_content">
            <quote-loader />
        </div>
        <div class="area_content" v-else>
            <div class="logo">
                <img src="@/assets/images/logo2.svg" alt="Logo">
            </div>
            <template v-if="paymentIntent && paymentIntent.requiresPaymentMethod">
                <h2>
                    Confirm your {{ paymentIntent.amount }} payment
                </h2>
                <h5>
                    Extra confirmation is needed to process your payment. Please confirm your payment by filling out your payment details below.
                </h5>
                <Form @submit="confirmCardPayment" v-slot="{ errors }" class="verify_form">
                    <div class="setting_wpr">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Name on Card</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.name }">
                                    <Field type="text" :autocomplete="'nofill'" name="name" v-model="cardForm.name" rules="required" placeholder="Enter Cardholder Name" />
                                </div>
                                <ErrorMessage name="name" class="text-danger" />
                            </div>
                        </div>
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Card Number</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.card_number }">
                                    <Field autocomplete="off" type="text" name="card_number" v-model="cardForm.card_number">
                                        <div id="card-element"></div>
                                    </Field>
                                </div>
                                <ErrorMessage name="card_number" class="text-danger" />
                            </div>
                        </div>
                        <div class="create_btn mt-3">
                            <button :disabled="verifyPaymentLoader" class="primary_btn" v-if="paymentIntent.requiresPaymentMethod">
                                <i class="fa fa-spinner fa-spin" v-if="verifyPaymentLoader"></i>&nbsp;
                                {{ verifyPaymentLoader ? 'Paying' : `Pay ${paymentIntent.amount}` }}
                            </button>
                        </div>
                    </div>
                </Form>
            </template>
            <template v-if="paymentIntent && paymentIntent.isSucceeded">
                <h2>
                    Payment Successful
                </h2>

                <Form class="verify_form">
                    <div class="setting_wpr">
                        <label class="input_label">This payment was already successfully confirmed.</label>
                        <div class="action_wpr m-0 mt-2">
                            <router-link :to="{ name: 'Login' }" class="btn save_btn go-back">Go Back</router-link>
                        </div>
                    </div>
                </Form>
            </template>
            <template v-if="isPaymentSucceeded">
                <h2>
                    Payment Successful
                </h2>

                <Form class="verify_form">
                    <div class="setting_wpr">
                        <label class="input_label">Payment was successfully confirmed.</label>
                        <div class="action_wpr m-0">
                            <router-link :to="{ name: 'Login' }" class="btn save_btn go-back">Go Back</router-link>
                        </div>
                    </div>
                </Form>
            </template>
            <template v-if="paymentIntent && paymentIntent.isCancelled">
                <h2>
                    Payment Cancelled
                </h2>
                <Form class="verify_form">
                    <div class="setting_wpr">
                        <label class="input_label">This payment was cancelled.</label>
                        <div class="action_wpr m-0">
                            <router-link :to="{ name: 'Login' }" class="btn save_btn go-back">Go Back</router-link>
                        </div>
                    </div>
                </Form>
            </template>
            <template v-if="paymentIntent && (paymentIntent.requiresAction || paymentIntent.requiresConfirmation)">
                <h2>
                    Payment Confirm
                </h2>
                <Form class="verify_form">
                    <div class="setting_wpr">
                        <label class="input_label" v-if="paymentConfirmError">{{ paymentConfirmError }}</label>
                        <button :disabled="verifyPaymentLoader" :class="{ 'mt-2': paymentConfirmError }" type="button" class="primary_btn" @click="confirmPaymentMethod()">
                            <i class="fa fa-spinner fa-spin" v-if="verifyPaymentLoader"></i>&nbsp;
                            {{ verifyPaymentLoader ? 'Confirming' : `Confirm your ${ paymentIntent.amount } payment` }}
                        </button>
                    </div>
                </Form>
            </template>
        </div>
    </div>
</template>

<script>
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState } from 'vuex'

    import axios from '@/services/axios'
    import Toastr from '@/utils/Toastr'

    const stripeInstance = window.Stripe(process.env.VUE_APP_STRIPE_KEY, {});

    export default {
        name: 'Payment Action Required',

        data () {
            return {
                tab: 'account',
                verifyPaymentLoader: false,
                cardForm: {},
                billingApi: process.env.VUE_APP_BILLING_API_URL,
                paymentIntent: null,
                paymentIntentLoader: false,
                isPaymentSucceeded: false,
                paymentConfirmError: '',
            }
        },

        components: {
            Field,
            Form,
            ErrorMessage
        },

        computed: {
            ...mapState({
                user: state => state.authModule.user,
            }),

            stripeElements () {
                return stripeInstance.elements();
            },
        },

        mounted () {
            const vm = this;

            vm.getPaymentIntent(vm.$route.params.id);
        },

        beforeUnmount () {
            const vm = this;

            if (vm.card && !vm.card._destroyed) {
                vm.card.destroy();
            }
        },

        methods: {
            async confirmCardPayment (form, { setFieldError }) {
                const vm = this;
                vm.verifyPaymentLoader  = true;

                const { paymentIntent, error } = await stripeInstance.confirmCardPayment(
                    vm.paymentIntent.clientSecret, {
                        payment_method: {
                            card: vm.card,
                            billing_details: { name: form.name }
                        },
                        setup_future_usage: 'off_session'
                    }
                );

                if (error) {
                    vm.verifyPaymentLoader = false;
                    setFieldError('card_number', error.message);
                } else {
                    vm.verifyPaymentLoader = true;

                    axios.post(`${vm.billingApi}/stripe/update-billing`, { paymentIntent }, {
                        headers: {
                            Accept: 'application/json',
                            Authorization: `Bearer ${vm.user.access_token}`,
                        },
                    }).then((resp) => {
                        if (vm.card) {
                            vm.card.destroy();
                        }

                        vm.verifyPaymentLoader  = false;
                        vm.paymentIntent        = null;
                        vm.isPaymentSucceeded   = true;

                        Toastr.success('Payment has been verified successfully!');
                    }).catch((err) => {
                        vm.verifyPaymentLoader = false;
                        Toastr.error('Looks like something went wrong, please try after some time!');
                    });
                }
            },

            getPaymentIntent (id) {
                const vm = this;

                vm.paymentIntentLoader = true;

                axios.post(`${vm.billingApi}/stripe/get-payment-intent`, { id }, {
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${vm.user.access_token}`,
                    },
                }).then((resp) => {
                    vm.paymentIntent        = resp.data;
                    vm.paymentIntentLoader  = false;

                    setTimeout(function () {
                        if (vm.paymentIntent.requiresPaymentMethod) {
                            vm.initializeStripe();
                        }
                    }, 10);
                }).catch((err) => {
                    vm.paymentIntentLoader = false;
                });
            },

            initializeStripe () {
                const vm = this;

                const style = {
                    base: {
                        color: '#5a5a5a',
                        lineHeight: '50px',
                        fontSmoothing: 'antialiased',
                        fontSize: '14px',
                        '::placeholder': {
                            color: '#aab7c4',
                        },
                    },
                    invalid: {
                        color: '#eb1414',
                        iconColor: '#eb1414',
                    },
                };

                vm.card = vm.stripeElements.create('card', { hidePostalCode: true, style });
                vm.card.mount('#card-element');
            },

            async confirmPaymentMethod () {
                const vm = this;

                vm.verifyPaymentLoader  = true;

                const { paymentIntent, error } = await stripeInstance.confirmCardPayment(
                    vm.paymentIntent.clientSecret, {
                        payment_method: vm.paymentIntent.payment_method,
                        setup_future_usage: 'off_session'
                    }
                );

                if (error) {
                    vm.verifyPaymentLoader = false;
                    vm.paymentConfirmError = error.message;

                    if (error.code == 'payment_intent_authentication_failure') {
                        vm.paymentIntent.requiresPaymentMethod = true;
                        vm.paymentIntent.requiresAction = false;
                        vm.initializeStripe();
                    }
                } else {
                    vm.verifyPaymentLoader = true;

                    axios.post(`${vm.billingApi}/stripe/update-billing`, { paymentIntent }, {
                        headers: {
                            Accept: 'application/json',
                            Authorization: `Bearer ${vm.user.access_token}`,
                        },
                    }).then((resp) => {
                        if (vm.card) {
                            vm.card.destroy();
                        }

                        vm.verifyPaymentLoader  = false;
                        vm.paymentIntent        = null;
                        vm.isPaymentSucceeded   = true;

                        Toastr.success('Payment has been verified successfully!');
                    }).catch((err) => {
                        vm.verifyPaymentLoader = false;
                        Toastr.error('Looks like something went wrong, please try after some time!');
                    });
                }
            },
        },
    };
</script>

<style scoped>
    .verify_account {
        height: 100vh;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .verify_account .area_content {
        max-width: 500px;
        width: 100%;
        text-align: left;
    }

    .verify_account .area_content .logo {
        margin: 0 0 40px;
    }

    .verify_account .area_content .logo img {
        max-width: 200px;
        height: auto;
        width: 100%;
    }

    .verify_account h2 {
        font-size: 24px;
        line-height: 30px;
        font-weight: 600;
        color: #121525;
        margin-bottom: 10px;
    }

    .verify_account h5 {
        font-size: 13px;
        line-height: 20px;
        color: #5a5a5a;
        font-weight: 500;
        max-width: 350px;
    }

    .verify_account .verify_form {
        border: 1px solid #e9e9e9;
        background: #f5f5f5;
        border-radius: 8px;
        padding: 20px 40px;
        margin-top: 30px;
    }

    .verify_account .verify_form .field_wpr {
        background: #fff;
    }

    .verify_account .more_action {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding: 15px 0;
    }

    .verify_account .more_action li {
        font-size: 13px;
        line-height: 18px;
        color: #5a5a5a;
        font-weight: 400;
        cursor: pointer;
    }

    .verify_account .more_action li i {
        margin-right: 7px;
    }

    .verify_account .primary_btn {
        position: relative;
        height: 50px;
        cursor: pointer;
    }

    .verify_account .primary_btn span {
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
    }

    .verify_account .primary_btn .suffix {
        position: absolute;
        right: 70px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 20px;
    }

    .verify_account h5 {
        font-size: 13px;
        line-height: 20px;
        color: #5a5a5a;
        font-weight: 500;
        position: relative;
        max-width: 100%;
    }

    .verify_account .login_btn {
        display: flex;
        justify-content: center;
        font-size: 13px;
        line-height: 18px;
        font-weight: 500;
        color: #5a5a5a;
        padding: 20px 0;
    }

    .verify_account .login_btn a {
        text-decoration: none;
        color: #2f7eed;
        padding: 0 5px;
    }

    .verify_account .primary_btn .prefix {
        position: absolute;
        left: 30px;
        top: 50%;
        transform: translateY(-50%);
    }

    .verify_account .primary_btn .fa-spinner.prefix {
        position: absolute;
        left: 30px;
        top: 30%;
        transform: translateY(-50%);
    }

    #card-error {
        color: #eb1414;
    }

    .StripeElement {
        border: 0;
        width: 100%;
        height: 50px;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        color: #5a5a5a;
        font-weight: 400;
        padding: 0 15px;
        box-sizing: border-box;
        background: transparent;
    }

    :deep(.__PrivateStripeElement),
    :deep(.__PrivateStripeElement iframe) {
        height: 50px !important;
    }

    :deep(.__PrivateStripeElement iframe .InputElement) {
        height: 50px !important;
    }

    .action_wpr .btn.go-back {
        height: 30px;
        line-height: 28px;
        text-decoration: none;
    }
</style>
